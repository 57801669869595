html,
body {
  -webkit-font-smoothing: antialiased;
  padding: 0;
  margin: 0;
  font-family:
   Roboto,
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  color: #474753;
  background: #FAFAFA;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}


@font-face {
  font-family: Roboto;
  src: url(/_next/static/media/RobotoFlex.801b1a15.woff2);
  weight: 100 1000;
  width: 25 151;
  font-display: swap;
}


@font-face {
  font-family: Rotor;
  src: url(/_next/static/media/rotor-VF.58f15869.woff2);
  weight: 100 900;
  font-display: swap;
}

a {
  color: #4025ac;
  text-decoration: underline;
  cursor: pointer;
  transition: color 150ms ease;
}

a:hover,
a:visited {
  color: #22879C;

}

.PageHeader p + h1 {
  margin-top: 1rem;
}

h2, h3, h4 {
  font-weight: 569;
  font-stretch: 62%;
  font-variation-settings: 'opsz' 39;
}

h2 {
  font-size: 2.625em;
  font-stretch: 32%;
  letter-spacing: -0.02em;
  margin: 0 0 0.619em 0;
}

h3 {
  font-size: 2em;
  letter-spacing: -0.01em;
  margin: 0 0 0.750em 0;
}

h4 {
  font-size: 1.5em;
  margin: 0 0 1em 0;
}

p + h2,
p + h3,
p + h4 {
  margin-top: 3.188rem;
}

iframe {
  display: block;
}

blockquote {
  font-size: 2rem;
  font-weight: 500;
  font-variation-settings: 'opsz' 144;
  font-stretch: 76%;
  position: relative;
  line-height: 1.125;
  margin: 4rem 0;

  &::before {
    content: "\201C";
    font-family: Rotor, sans-serif;
    position: absolute;
    font-size: 3em;
    top: -0.2em;
    left: -0.6em;
    color: #4025ac;
  }
}

cite {
  display: block;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-style: normal;
  margin-top: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #17171c75;
}

.author {
  display: block;
  font-size: 0.5em;
  letter-spacing: 0;
}

.PostContent .imageContainer,
.PostContent iframe,
.PostContent pre {
  position: relative;
  display: block;
  margin: 3rem 0;
}

@media screen and (min-width: 48em) {
  .PostContent .imageContainer,
  .PostContent iframe,
  .PostContent pre {
    margin: 6rem 0;
  }
}

.PostContent .imageContainer::before,
.codepen::before,
.PostContent iframe::before,
.PostContent pre::before,
.PostContent .videoPlayer::before {
  content: "";
  width: calc(100% + 2rem);
  background: #ebeaf8;
  height: calc(100% + 2rem);
  display: block;
  top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  position: absolute;
}

@media screen and (min-width: 48em) {
  .PostContent .imageContainer::before,
  .codepen::before,
  .PostContent iframe::before,
  .PostContent pre::before,
  .PostContent .videoPlayer::before {
    width: calc(100% + 6rem);
    height: calc(100% + 6rem);
    top: -3rem;
    margin-left: -3rem;
    margin-right: -3rem;
  }

}

.PostContent .imageContainer > img,
iframe,
pre,
.PostContent .videoPlayer {
  box-shadow: 0px 10px 20px #17171c75;
  position: relative;
  display: block;
}

.PostContent pre + pre {
  margin-top: 8rem;
}

.codepen {
  position: relative;
  display: block;
  margin: 6rem 0;
  box-shadow: 0px 10px 20px #17171c75;
}

.PostContent .videoPlayer iframe {
  margin: 0;
}

.videoPlayer {
  position: relative;
  padding-top: 56.25%;
  margin: 6em auto;
}

.videoPlayer > iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  -webkit-clip-path: inset(50%);
          clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
@font-face {
font-family: '__roboto_d8feeb';
src: url(/_next/static/media/801b1a15efbd64d1-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__roboto_Fallback_d8feeb';src: local("Arial");ascent-override: 93.26%;descent-override: 24.54%;line-gap-override: 0.00%;size-adjust: 99.48%
}.__className_d8feeb {font-family: '__roboto_d8feeb', '__roboto_Fallback_d8feeb'
}

@font-face {
font-family: '__rotor_2d3252';
src: url(/_next/static/media/58f158698d7aad2c-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__rotor_Fallback_2d3252';src: local("Arial");ascent-override: 69.77%;descent-override: 13.95%;line-gap-override: 0.00%;size-adjust: 143.33%
}.__className_2d3252 {font-family: '__rotor_2d3252', '__rotor_Fallback_2d3252'
}

